import { DisplayMode, Ratio } from '@canalplus/mycanal-commons';
import { Binder } from '@canalplus/one-navigation';
import { Template, TitleDisplayMode } from '@canalplus/sdk-hodor';
import classNames from 'classnames/bind';
import type { JSX } from 'react';
import ContentGridTemplateItem from '../../../components/ContentGrid/ContentGridTemplateItem';
import HeadingIdentityV5 from '../../../components/HeadingIdentityV5/HeadingIdentityV5';
import {
  IdentityCover,
  isCoverV5,
} from '../../../components/IdentityCover/IdentityCover';
import LazyLoader from '../../../components/LazyLoader/LazyLoader';
import { useIsFrom } from '../../../components/Page/useIsFrom';
import Spinner from '../../../components/Spinner/Spinner';
import TemplateHeaderCanal from '../../../components/TemplateHeader/TemplateHeader';
import { getLazyLoaderContentGridOptions } from '../../../constants/strates';
import { useInvariantSelector } from '../../../helpers/hooks/useInvariantSelector';
import { useOnFocusable } from '../../../helpers/hooks/useOnFocusable';
import { useTracking } from '../../../helpers/hooks/useTracking';
import { MIDDLEWARE_STRATE } from '../../../helpers/oneNavigation/middleware';
import { featUpcomingHodorStickerSelector } from '../../../store/slices/application-selectors';

import getOverlappingClassnameWithCoverV5 from '../../helpers/IdentityV5Helpers/getOverlappingClassname';

import styles from './ContentGridTemplate.css';
import type { ContentGridTemplateProps } from './ContentGridTemplate.types';
import { ContentGridVirtual } from './ContentGridVirtual';

const cx = classNames.bind(styles);

function ContentGridTemplate({
  data,
  header,
  imageRatio = Ratio.Ratio169,
  imageSize = 'normal',
  isFetchingNextPage = false,
  isRemovableItem = false,
  isSearch = false,
  isSearchDeeplink = false,
  listType,
  middleware,
  onClickRemove,
  titleDisplayMode = TitleDisplayMode.All,
  focusManager,
  binderId,
  classNameGridItem,
  isVirtualization = false,
  offsetTop,
  isInitialOffsetVirtualization,
}: ContentGridTemplateProps): JSX.Element {
  const isFeatUpcomingHodorSticker = useInvariantSelector(
    featUpcomingHodorStickerSelector
  );
  const isFromDetail = useIsFrom(Template.DetailPage);

  const { title, subtitle, button } = header || {};

  const { sendTracking } = useTracking();

  const [firstPage] = data?.pages || [];
  const tracking = firstPage?.tracking;
  const resultsLength = firstPage?.contents?.length;

  const focusState = focusManager?.getFocusState();

  useOnFocusable(
    focusManager,
    typeof resultsLength !== 'undefined' && resultsLength > 0
  );

  const onClickProp =
    isSearch && tracking
      ? {
          onClick: () => sendTracking({ tracking }),
        }
      : {};

  const renderGrid = () => {
    const { increment, initialDisplayCount } = getLazyLoaderContentGridOptions({
      isSearch,
      isSearchDeeplink,
    });

    const initialDisplayValue = Math.max(
      initialDisplayCount,
      focusState ? focusState.elementIndex + 1 : 0
    );

    return (
      <LazyLoader
        initialDisplayCount={initialDisplayValue}
        loadMoreCount={increment}
      >
        {data?.pages?.map((page) => {
          return page.contents?.map((content) => {
            // DisplayMode.FULLSCREEN is without capital S -  waiting Hodor fix
            const isPlayBackIconShown =
              content.onClick &&
              'displayMode' in content.onClick &&
              content.onClick.displayMode?.toLowerCase() ===
                DisplayMode.FULLSCREEN;

            const isCreativeMedia = content.type === Template.CreativeMedia;

            return (
              <li
                className={cx(
                  'contentGrid__gridItem',
                  {
                    'contentGrid__gridItem--detailV5': isFromDetail,
                    'contentGrid__gridItem--creativeMedia': isCreativeMedia,
                  },
                  classNameGridItem
                )}
                key={'hash' in content ? content.hash : content.contentID}
                {...onClickProp}
              >
                <ContentGridTemplateItem
                  content={content}
                  imageSize={imageSize}
                  ratio={imageRatio}
                  titleDisplayMode={titleDisplayMode}
                  isRemovableItem={isRemovableItem}
                  onClickRemove={onClickRemove}
                  listType={listType}
                  isPlayBackIconShown={isPlayBackIconShown}
                  isSearch={isSearch}
                  isFeatUpcomingHodorSticker={isFeatUpcomingHodorSticker}
                />
              </li>
            );
          });
        })}
      </LazyLoader>
    );
  };

  const isVirtualizationDeactivated =
    !isVirtualization ||
    !$_BUILD_RENDERMODE_CSR ||
    (data?.pages[0]?.contents?.length ?? 0) < 20;

  if (
    data?.pages?.[0] &&
    'cover' in data.pages[0] &&
    isCoverV5(data.pages[0].cover)
  ) {
    const { cover } = data.pages[0];

    return (
      <>
        <IdentityCover cover={cover} />
        <div
          className={cx(
            'contentGridTemplate',
            { 'contentGridTemplate--detailV5': isFromDetail },
            getOverlappingClassnameWithCoverV5(cover)
          )}
        >
          <Binder
            binderId={binderId}
            middleware={middleware || MIDDLEWARE_STRATE}
          >
            <div className={cx('contentGridTemplate__heading')}>
              <HeadingIdentityV5 cover={cover} title={title} />
            </div>
            {isVirtualizationDeactivated ? (
              <ul
                data-ratio={`${imageRatio}${imageSize}`}
                className={styles.contentGrid}
              >
                {renderGrid()}
              </ul>
            ) : (
              <ContentGridVirtual
                data={data}
                imageRatio={imageRatio}
                imageSize={imageSize}
                onClickProp={onClickProp}
                classNameGridItem={classNameGridItem}
                offsetTop={offsetTop}
                contentGridItemProps={{
                  isRemovableItem,
                  isSearch,
                  listType,
                  onClickRemove,
                  titleDisplayMode,
                }}
                focusManager={focusManager}
                isInitialOffsetVirtualization={isInitialOffsetVirtualization}
              />
            )}
            {isFetchingNextPage && (
              <div className={styles.spinner__content}>
                <Spinner size={5} />
              </div>
            )}
          </Binder>
        </div>
      </>
    );
  }

  // without cover v5
  return (
    <div
      className={cx('contentGridTemplate', {
        'contentGridTemplate--detailV5': isFromDetail,
      })}
    >
      <Binder binderId={binderId} middleware={middleware || MIDDLEWARE_STRATE}>
        {title && (
          <div
            className={cx('contentGrid__header', {
              'contentGrid__header--detailV5': isFromDetail,
            })}
          >
            <TemplateHeaderCanal
              button={button}
              subtitle={subtitle}
              title={title}
            />
          </div>
        )}
        {isVirtualizationDeactivated ? (
          <ul
            data-ratio={`${imageRatio}${imageSize}`}
            className={styles.contentGrid}
          >
            {renderGrid()}
          </ul>
        ) : (
          <ContentGridVirtual
            data={data}
            imageRatio={imageRatio}
            imageSize={imageSize}
            onClickProp={onClickProp}
            classNameGridItem={classNameGridItem}
            offsetTop={offsetTop}
            contentGridItemProps={{
              isRemovableItem,
              isSearch,
              listType,
              onClickRemove,
              titleDisplayMode,
            }}
            focusManager={focusManager}
            isInitialOffsetVirtualization={isInitialOffsetVirtualization}
          />
        )}
        {isFetchingNextPage && (
          <div className={styles.spinner__content}>
            <Spinner size={5} />
          </div>
        )}
      </Binder>
    </div>
  );
}

export default ContentGridTemplate;
